import { defineStore } from 'pinia';
//import axios from 'axios';
import router from "../router/index";

import { fetchWrapper } from '@/helpers';

const baseUrl = `${import.meta.env.VITE_API_URL}/auth`;

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        returnUrl: null
    }),
    actions: {
        async login(email, password) {
            //const user = await axios.post(`${baseUrl}/login`, { email, password });
            const user = await fetchWrapper.post(`${baseUrl}/login`, { email, password });

            // update pinia state
            this.user = user.data;

            // store user details and jwt in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user.data));

            // redirect to previous url or default to home page
            router.push('/');
        },
        async register(name, email, password, c_password) {
            const user = await fetchWrapper.post(`${baseUrl}/register`, { name, email, password, c_password });
            router.push('/login');
        },
        async forgotPassword(email) {
            await fetchWrapper.post(`${baseUrl}/forgot-password`, { email });
            this.user = null;
            localStorage.removeItem('user');
        },
        async resetPassword(password, token) {
            await fetchWrapper.post(`${baseUrl}/reset-password`, { password, token });
            router.push('/login');
        },
        async logout() {
            // await fetchWrapper.post(`${baseUrl}/logout`)
            this.user = null;
            localStorage.removeItem('user');
            router.push('/login');
        }
    }
});
